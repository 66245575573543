<script lang="ts">
    export let alt = ''
    export let external = false
    export let height: number
    export let lazy = false
    export let role = ''
    export let src: string
    export let width: number
</script>

<picture class={$$props.class} {...$$restProps}>
    <slot />

    <img
        alt={alt || ''}
        {height}
        loading={lazy ? 'lazy' : undefined}
        role={role ? role : !alt ? 'presentation' : undefined}
        src={external ? src : `${import.meta.env.VITE_CLOUDINARY_URL}${src}`}
        {width}
    />
</picture>
